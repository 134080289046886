import {call, put, takeLatest} from "redux-saga/effects";
import {
    assignRolesToUser,
    getUserFailure,
    getUserReports,
    getUserReportSuccess,
    setFalseToIsLoading
} from "../../reducers/PortalReducer";
import {apiCall} from "../../apiCall/apiCall";
import {store} from "../../store";
import {getRoleFailure, getUserRoles} from "../../reducers/GroupReducer";
import {toast} from "react-toastify";

const getIdToken = () => store.getState().authReducer.idToken;

export function* workGetClientReports(action) {
    try {
        if (getIdToken()) {
            const response = yield call(apiCall, `/portal_user/${action.payload.userId}`, "get", null, getIdToken());
            yield put(getUserReportSuccess(response.data));
        }
    } catch ({message}) {
        yield put(getUserFailure(message));
    }
}

export function* workAssignRolesToUser(action) {
    try {
        yield call(apiCall, `/portal_user/${action.payload.id.email}/assign_roles`, "put", action.payload.roles, getIdToken());
        yield put(setFalseToIsLoading())
        yield put(getUserRoles({id: action.payload.id}))
        toast.success("Roles assigned to User successfully");
    } catch ({message}) {
        yield put(getRoleFailure(message));
    }
}

export function* portalSaga() {
    yield takeLatest(getUserReports().type, workGetClientReports);
    yield takeLatest(assignRolesToUser().type, workAssignRolesToUser)
}