import React, {useEffect, useMemo, useState} from "react";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    getKeyValue,
    Input,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    useDisclosure,
    User
} from "@nextui-org/react";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {
    changeRole, deleteUser, removeAdminRole, setSearch, setSelectedUser
} from "../../../redux/reducers/UserReducer";
import ModalAssignRoles from "../../modal/ModalAssignRoles";
import {getUserRoles, setUserRoles} from "../../../redux/reducers/GroupReducer";
import PaginationUniv from "../../uiComponents/Pagination";
import {SearchIcon} from "../../icons/SearchIcon";
import {assignRolesToUser} from "../../../redux/reducers/PortalReducer";
import ModalConfirmDelete from "../../modal/ModalConfirmDelete";
import {setIsAssigned} from "../../../redux/reducers/StyleReducer";

function UserList({users, isLoading}) {

    const [page, setPage] = React.useState(1);
    const rowsPerPage = 6; // Change to 10 as per your requirement
    const {onOpen, onClose, isOpen} = useDisclosure()
    const {onOpen: onOpenConfirm, onClose: onCloseConfirm, isOpen: isOpenConfirm} = useDisclosure()
    const dispatch = useDispatch()
    const {selectedUser} = useSelector(state => state.userReducer)
    const paginatedUsers = useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage;
        return users?.slice(startIndex, startIndex + rowsPerPage);
    }, [users, page]);
    const {search, isChangingRole} = useSelector(state => state.userReducer)


    const pages = useMemo(() => {
        return users?.length ? Math.ceil(users.length / rowsPerPage) : 0;
    }, [users?.length, rowsPerPage]);

    const loadingState = isLoading || users?.length === 0 ? "loading" : "idle";

    function handleAssign(item) {
        onOpen()
        dispatch(setIsAssigned())
        dispatch(setSelectedUser(item))
    }


    useEffect(() => {
        if (!isOpen) {
            dispatch(setSelectedUser(""))
            dispatch(setUserRoles([]))
        }
    }, [isOpen]);

    function handleDelete(user) {
        onOpenConfirm()
        dispatch(setSelectedUser(user))
    }


    function handleRoleChange(item) {
        let roleStatus = item?.app_metadata?.user_roles.length ? "ADMIN" : "USER"
        let role_id = "rol_Y4I8wOkJlIFjE8GZ"
        dispatch(setSelectedUser(item.user_id))
        if (roleStatus === "ADMIN") {
            dispatch(removeAdminRole({userId: item.user_id, roleId: role_id}))
        } else {
            dispatch(changeRole({userId: item.user_id, role: {id: role_id, name: "ADMIN"}}))
        }
    }


    return (<div>
        <Input
            inputMode={"search"}
            isClearable
            size={"sm"}
            className="w-full shadow-xl font-bold mb-3"
            placeholder="Search by name or email"
            startContent={<SearchIcon/>}
            value={search}
            onClear={() => dispatch(setSearch(""))}
            classNames={{
                input: ["bg-transparent", "text-black/90 dark:text-white/90",],
            }}
            onChange={(e) => {
                dispatch(setSearch(e.target.value))
                setPage(1)
            }}
        />
        <Table
            key={isChangingRole}
            aria-label="Example table with client async pagination"
            bottomContent={pages > 0 ? (<div className="flex w-full justify-center">
                <PaginationUniv setPage={setPage} page={page} pages={pages}/>
            </div>) : null}
        >
            <TableHeader>
                <TableColumn key="picture">Picture</TableColumn>
                <TableColumn key="email">Email</TableColumn>
                <TableColumn key="status">Status</TableColumn>
                <TableColumn key="actions">Actions</TableColumn>
            </TableHeader>
            <TableBody
                items={paginatedUsers ?? []}
                loadingContent={<Spinner/>}
                loadingState={loadingState}
            >
                {(item) => (<TableRow key={item?.user_id}>
                    {(columnKey) => {
                        if (columnKey === "picture") {

                            return (<TableCell>
                                <User
                                    avatarProps={{radius: "lg", src: item.picture}} name={item.nickname}>
                                </User>
                            </TableCell>);
                        } else if (columnKey === "status") {
                            return <TableCell>
                                <p className={"small"}>
                                    {
                                        !isChangingRole ? (selectedUser === item.user_id ?
                                                <span className={"bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300"}>
                                                    wait...
                                            </span> : (item?.app_metadata?.user_roles.length ?
                                                        <span
                                                        className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">ADMIN</span>
                                                    :
                                                    <span
                                                        className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">User</span>
                                                )) :
                                            (item?.app_metadata?.user_roles.length ?
                                                    <span
                                                        className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">ADMIN</span>
                                                    :
                                                    <span
                                                        className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">User</span>
                                            )
                                    }
                                </p>
                            </TableCell>
                        } else if (columnKey === "actions") {
                            return <TableCell>
                                <div className="relative ">
                                    <Dropdown className="bg-background border-1 border-default-200">
                                        <DropdownTrigger>
                                            <Button isIconOnly radius="full" variant="light">
                                                <BsThreeDotsVertical size={"24px"}/>
                                            </Button>
                                        </DropdownTrigger>
                                        <DropdownMenu aria-label={"menu"}>
                                            <DropdownItem onClick={() => handleAssign(item)}>
                                                Assign Roles
                                            </DropdownItem>
                                            <DropdownItem onClick={() => handleDelete(item)}>
                                                Delete User
                                            </DropdownItem>
                                            <DropdownItem onClick={() => handleRoleChange(item)}>
                                                {item?.app_metadata?.user_roles.length ?
                                                    "Make User" :
                                                    "Make Admin"
                                                }
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </TableCell>
                        } else {
                            return <TableCell>{getKeyValue(item, columnKey)}</TableCell>;
                        }
                    }}
                </TableRow>)}
            </TableBody>
        </Table>
        <ModalAssignRoles
            id={selectedUser}
            isOpen={isOpen}
            onClose={onClose}
            assignRolesToItem={assignRolesToUser}
            getAssignedRoles={getUserRoles}
            setAssignedRoles={setUserRoles}
            setSelectedItem={setSelectedUser}
            selectedItem={selectedUser.email}
        />
        <ModalConfirmDelete
            id={selectedUser.user_id}
            name={"User"}
            onClose={onCloseConfirm}
            isOpen={isOpenConfirm}
            itemTitle={selectedUser.nickname}
            deleteItem={deleteUser}
        />
    </div>);
}

export default UserList;
