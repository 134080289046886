import React, {useEffect, useMemo} from "react";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    getKeyValue,
    Input,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    Tooltip,
    useDisclosure
} from "@nextui-org/react";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import ModalConfirmDelete from "../../modal/ModalConfirmDelete";
import {deleteReport, getCategoryReports, setSearch, setSelectedReport} from "../../../redux/reducers/ReportReducer";
import ModalReportSave from "../../modal/ModalReportSave";
import {setSelectedCategoryId} from "../../../redux/reducers/GroupReducer";
import {SearchIcon} from "../../icons/SearchIcon";
import PaginationUniv from "../../uiComponents/Pagination";
import {AiOutlineEdit} from "react-icons/ai";
import {MdOutlineDeleteOutline} from "react-icons/md";
import {useNavigate, useParams} from "react-router-dom";
import MultipleSelectBox from "../../uiComponents/MultipleSelectBox";
import {setSelectedCategories} from "../../../redux/reducers/CategoryReducer";

function ReportList({reports, isLoading}) {
    const [page, setPage] = React.useState(1);
    const rowsPerPage = 6; // Change to 10 as per your requirement
    const dispatch = useDispatch()
    const {onOpen: onOpenConfirm, onClose: onCloseConfirm, isOpen: isOpenConfirm} = useDisclosure()
    const {onOpen, onClose, isOpen, onOpenChange} = useDisclosure()
    const {selectedReport, search} = useSelector(state => state.reportReducer)
    const {categories, selectedCategories} = useSelector(state => state.categoryReducer)
    const navigate = useNavigate()
    const {id} = useParams()

    useEffect(() => {
        return () => {
            dispatch(setSelectedCategories([]))
        }
    }, [])

    const paginatedReport = useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage;
        return reports?.slice(startIndex, startIndex + rowsPerPage);
    }, [reports, page]);

    const pages = useMemo(() => {
        return reports?.length ? Math.ceil(reports.length / rowsPerPage) : 0;
    }, [reports?.length, rowsPerPage]);


    function handleDelete(item) {
        onOpenConfirm()
        dispatch(setSelectedReport(item))
    }

    const loadingState = isLoading ? "loading" : "idle";

    function handleEdit(item) {
        onOpen()
        dispatch(setSelectedReport(item))
        dispatch(setSelectedCategoryId(item.category_id))
    }


    function selectMultipleCategory(e) {
        let categoryIds = Array.from(e)
        dispatch(setSelectedCategories(categoryIds))
        dispatch(getCategoryReports({search, categoryId: categoryIds}))
        navigate("/admin/report/" + categoryIds)
    }

    useEffect(() => {
        if (id) {
            dispatch(setSelectedCategories([id]))
        }
    }, [])

    return (
        <div>
            <div className="text-right">
                <Button onClick={() => {
                    onOpen()
                    dispatch(setSelectedReport({report_id: "", title: "", description: "", link: ""}))
                    dispatch(setSelectedCategoryId())
                }}
                        className={"mb-5 bg-secondColor text-white font-bold"}
                        radius={"sm"}>
                    Add Report
                </Button>
            </div>

            <div className="flex items-start gap-3 mb-3 justify-between">
                <Tooltip content="Press Enter to search">
                    <Input
                        inputMode={"search"}
                        isClearable
                        size={"sm"}
                        className={`${id ? "w-[100%]" : "w-[70%]"} font-bold`}
                        placeholder="Search by title"
                        startContent={<SearchIcon/>}
                        value={search}
                        onClear={() => dispatch(setSearch(""))}
                        onChange={(e) => {
                            dispatch(setSearch(e.target.value))
                            setPage(1)
                        }}
                    />
                </Tooltip>
                <MultipleSelectBox categoryIds={selectedCategories} handleChange={selectMultipleCategory}
                                   categories={categories}/>

            </div>

            <Table
                aria-label="Example table with client async pagination"
                bottomContent={
                    pages > 0 ? (
                        <div className="flex w-full justify-center">
                            <PaginationUniv setPage={setPage} page={page} pages={pages}/>
                        </div>
                    ) : null
                }
            >
                <TableHeader>
                    <TableColumn key="title">Title</TableColumn>
                    <TableColumn key="description">Description</TableColumn>
                    <TableColumn key="category_title">category</TableColumn>
                    <TableColumn key="actions">Actions</TableColumn>
                </TableHeader>
                <TableBody
                    emptyContent={"no items here ."}
                    items={paginatedReport ?? []}
                    loadingContent={<Spinner/>}
                    loadingState={loadingState}
                >
                    {(item) => (
                        <TableRow key={item?.report_id}>
                            {(columnKey) => {
                                if (columnKey !== "actions") {
                                    return (
                                        <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                                    );
                                } else {
                                    return <TableCell>
                                        <div className="relative">
                                            <Dropdown className="bg-background border-1 border-default-200">
                                                <DropdownTrigger>
                                                    <Button isIconOnly radius="full" variant="light">
                                                        <BsThreeDotsVertical size={"24px"}/>
                                                    </Button>
                                                </DropdownTrigger>
                                                <DropdownMenu aria-label={"menu"}>
                                                    <DropdownItem onClick={() => handleEdit(item)}>
                                                        <div className={'flex   gap-x-2  items-center justify-start'}>
                                                            <AiOutlineEdit/>
                                                            Edit
                                                        </div>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => handleDelete(item)}>
                                                        <div className={'flex gap-x-2 items-center justify-start'}>
                                                            <MdOutlineDeleteOutline/>
                                                            Delete
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </TableCell>
                                }
                            }}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <ModalConfirmDelete
                deleteItem={deleteReport}
                name={"Report"}
                itemTitle={selectedReport.title}
                id={selectedReport.report_id}
                isOpen={isOpenConfirm} onClose={onCloseConfirm}
            />
            <ModalReportSave
                isOpen={isOpen}
                onClose={onClose}
                onOpenChange={onOpenChange}
            />
        </div>
    );
}


export default ReportList;
