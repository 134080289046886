import React, {useEffect} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/home/Home";
import Admin from "./pages/admin/Admin";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch, useSelector} from "react-redux";
import {
    getIdTokenFromLocalStorage,
    setIdTokenToLocalStorage,
    setIsExpired,
    setUserRole
} from "./redux/reducers/AuthReducer";
import Loading from "./components/loading/Loading";
import axios from "axios";
import {apiCall} from "./redux/apiCall/apiCall";
import NotFoundPage from "./pages/notFound/NotFound";
import 'react-loading-skeleton/dist/skeleton.css'
import Content from './components/content/Content'
import Role from "./pages/admin/role/Role";
import User from "./pages/admin/user/User";
import Category from "./pages/admin/category/Category";
import Report from "./pages/admin/report/Report";
import Domain from "./pages/domain/Domain";

function App() {

    const dispatch = useDispatch()
    const {userRole, idToken, isExpired} = useSelector(state => state.authReducer)
    const {
        user,
        isLoading,
            isAuthenticated,
        getIdTokenClaims,
        getAccessTokenSilently
    } = useAuth0();

    const getAccessToken = async () => {
        if (isExpired) {
            try {
                const token = await getAccessTokenSilently();
                document.token = token;
                getIdToken()
            } catch (e) {
                // console.error("SILENTLY AQUIRED USER - TOKEN", e);
            } finally {
                dispatch(setIsExpired(false))
            }
        }
    }

    useEffect(() => {
        getAccessToken()
    }, [isExpired])

    async function updateAllowedCallbackURLs() {
        let token = localStorage.getItem("api_m_token")
        const accessToken = token && token;
        const domain = process.env.REACT_APP_AUTH0_DOMAIN;
        const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
        const callbackURLs = [
            process.env.REACT_APP_ALLOWED_CALL_BACK_URLS,
        ];
        try {
            const response = await axios.patch(
                `https://${domain}/api/v2/clients/${clientId}`,
                {
                    callbacks: callbackURLs,
                    allowed_logout_urls: callbackURLs,
                    web_origins: callbackURLs
                }
                ,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                }
            )
        } catch (error) {
            console.error('Failed to update Allowed Callback URLs:', error);
        }
    }

    async function getIdToken() {
        if (!isAuthenticated) return;
        try {
            const claims = await getIdTokenClaims();
            const idToken = claims.__raw;
            const iat = claims.iat * 1000;
            const currentTime = new Date().getTime();
            if (currentTime < iat) {
                setTimeout(getIdToken, iat - currentTime);
            } else {
                dispatch(setIdTokenToLocalStorage(idToken));
            }
        } catch (error) {
            alert("The id_token was not received");
        }
    }

    function getUserRole() {
        if (user && user.user_roles && user.user_roles.length) {
            dispatch(setUserRole(user.user_roles[0]));
        }
    }

    async function getApiManagementToken() {
        if (!idToken) return;
        try {
            const res = await apiCall("/auth", 'get', null, idToken);
            const accessToken = res.data["access_token"];
            localStorage.setItem("api_m_token", accessToken);
        } catch (err) {
            console.error("Error retrieving API management token:", err.message);
        }
    }

    useEffect(() => {
        getApiManagementToken()
    }, [idToken])

    useEffect(() => {
        if (user) {
            getIdToken()
            getUserRole()
            dispatch(getIdTokenFromLocalStorage())
        }
    }, [user]);


    return <div>
        {isLoading ? (
            <Loading type="cylon" color="black"/>
        ) : (
            <Routes>
                <Route path="/" element={<Home/>}>
                    <Route path="/report/:id/:link" element={<Content/>}/>
                </Route>
                {userRole && (
                    <Route
                        path="/admin"
                        element={userRole === "ADMIN" ? <Admin/> : <Navigate to="/"/>}
                    >
                        <Route path={"user"} element={<User/>}/>
                        <Route path={"role"} element={<Role/>}/>
                        <Route path={"report"} element={<Report/>}/>
                        <Route path={"report/:id"} element={<Report/>}/>
                        <Route path={"category"} element={<Category/>}/>
                        <Route path={"domain"} element={<Domain/>}/>
                    </Route>
                )}
                <Route path="*" element={<NotFoundPage/>}/>
            </Routes>
        )}
    </div>
}


export default App