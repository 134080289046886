import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCategoryReports} from "../../../redux/reducers/ReportReducer";
import ReportList from "../../../components/admin/report/ReportList";
import {useLocation, useParams} from "react-router-dom";
import {getCategory} from "../../../redux/reducers/CategoryReducer";

function Report() {
    const dispatch = useDispatch()
    const {reports, isLoading, search} = useSelector(state => state.reportReducer)
    const {idToken} = useSelector(state => state.authReducer)
    const {id} = useParams()

    useEffect(() => {
        if (id) {
            dispatch(getCategoryReports({categoryId: [id], search}))
        } else {
            dispatch(getCategoryReports({categoryId: [id], search}))
        }
    }, [idToken, id])

    return (
        <div>
            <ReportList
                reports={reports.filter(rp => rp.title.toLowerCase().includes(search.toLowerCase()))}
                isLoading={isLoading}
            />
        </div>
    );
}

export default Report;

