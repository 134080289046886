import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from '@nextui-org/react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {addUserStart, setEmailVerified} from '../../redux/reducers/UserReducer';
import {FiEye, FiEyeOff} from "react-icons/fi";

function ModalUser({isOpen, onClose, onOpenChange, message}) {
    const dispatch = useDispatch();

    const [showPassword, setShowPassword] = useState(false);
    const {
        register,
        reset,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const {
        isUserSaved,
        emailVerified
    } = useSelector((state) => state.userReducer);

    useEffect(() => {
        reset({
            username: "",
            password: "",
            email: "",
        })
        dispatch(setEmailVerified(false))
    }, [isOpen]);


    function submit(data) {
        dispatch(addUserStart({...data, emailVerified, onClose}));
    }

    return (
        <Modal placement="center" isOpen={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                <ModalHeader className="flex flex-col gap-1">
                    <h3>Add User</h3>
                </ModalHeader>
                <ModalBody>
                    <form id="user-form" onSubmit={handleSubmit(submit)}>
                        <Input
                            {...register('username', {required: true})}
                            className="mt-2"
                            radius="none"
                            type="text"
                            placeholder="Username"
                        />
                        {errors.username && <p className="text-red-500">Username is required.</p>}
                        <div className="relative mt-2 bg-gray-100">
                            <Input
                                {...register('password', {required: true})}
                                className="pr-10 bg-transparent"
                                radius="none"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                style={{zIndex: 1}}
                            />
                            <button
                                type="button"
                                className="absolute top-1/2 right-3 transform -translate-y-1/2 bg-transparent border-none p-0 cursor-pointer z-10" // Ensure button is on top of input
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? <FiEyeOff size={18}/> : <FiEye size={18}/>}
                            </button>
                        </div>
                        {errors.password && <p className="text-red-500">Password is required.</p>}
                        <Input
                            {...register('email', {required: true, pattern: /^\S+@\S+\.\S+$/})}
                            className="mt-2"
                            radius="none"
                            type="email"
                            placeholder="Email"
                        />
                        {errors.email && <p className="text-red-500">Valid email is required.</p>}
                        <Checkbox
                            className="mt-2"
                            onChange={(e) => dispatch(setEmailVerified(e.target.checked))}
                            checked={emailVerified}
                        >
                            Email Verified
                        </Checkbox>
                        {message && <p className="bg-danger text-white rounded p-2 my-2">{message} !</p>}
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" variant="light" onPress={onClose}>
                        Close
                    </Button>
                    <Button type="submit" form="user-form" color="primary">
                        Save
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalUser;
