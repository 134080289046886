import {Chip, Select, SelectItem} from "@nextui-org/react";
import {useParams} from "react-router-dom";


function MultipleSelectBox({categoryIds, categories, handleChange}) {

    const {id} = useParams()



    return (
        <Select
            onSelectionChange={handleChange}
            items={categories}
            placeholder="Select multiple category"
            selectedKeys={id ? id.split(",") : []}
            variant="faded"
            isMultiline={true}
            selectionMode="multiple"
            aria-label={"menu"}
            classNames={{
                base: "max-w-xs",
                trigger: "min-h-unit-12 py-3",
            }}
            renderValue={(items) => {
                return (
                    <div className="flex flex-wrap gap-2">
                        {items.map((item) => (
                            <Chip key={item.data.category_id}>{item.data.title}</Chip>
                        ))}
                    </div>
                );
            }}
            fullWidth color={"default"}>
            {(category) => (
                <SelectItem key={category.category_id} textValue={category.title}>
                    <div className="flex gap-2 items-center">
                        <div className="flex flex-col">
                            <span className="text-small">{category.title}</span>
                        </div>
                    </div>
                </SelectItem>
            )}
        </Select>
    );
}

export default MultipleSelectBox;