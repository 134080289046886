import {call, put, takeLatest} from "redux-saga/effects";
import {apiCall} from "../../apiCall/apiCall";
import {store} from "../../store";
import {
    addDomain,
    assignRolesToDomain,
    deleteDomain,
    editDomain,
    getDomain,
    getDomainFailure,
    getDomainSuccess,
    setIsSavedLoading,
    setSelectedDomain
} from "../../reducers/DomainReducer";
import {toast} from "react-toastify";
import {getRoleFailure} from "../../reducers/GroupReducer";

const getIdToken = () => store.getState().authReducer.idToken;

export function* workGetDomains() {
    if (getIdToken()) {
        try {
            const response = yield call(apiCall, `/domain`, "get", null, getIdToken());
            yield put(getDomainSuccess(response.data));
        } catch ({message}) {
            yield put(getDomainFailure(message));
        }
    }
}

export function* workAddDomain(action) {
    try {
        yield call(apiCall, `/domain`, "post", action.payload.data, getIdToken());
        yield call(workGetDomains);
        yield put(setSelectedDomain({name: ""}))
        toast.success("added successfully!")
        action.payload.close()
    } catch ({message}) {
        toast.error(message)
        yield put(getDomainFailure(message));
    } finally {
        yield put(setIsSavedLoading(false))
    }
}

export function* workEditDomain(action) {
    const {data, id, close} = action.payload
    try {
        yield call(apiCall, `/domain/${id}`, "put", data, getIdToken());
        yield put(setIsSavedLoading(false))
        yield put(setSelectedDomain({name: ""}))
        toast.success("edited successfully!")
        close()
        yield call(workGetDomains);
    } catch ({message}) {
        toast.error(message)
        yield put(getDomainFailure(message));
    }
}


export function* workDeleteDomain(action) {
    console.log(action.payload)
    try {
        yield call(apiCall, `/domain/${action.payload.id}`, "delete", null, getIdToken());
        toast.success("domain is deleted successfully!")
        yield call(workGetDomains);
    } catch ({message}) {
        toast.error(message)
        yield put(getDomainFailure(message));
    }
}

export function* workAssignRolesToDomain(action) {
    try {
        yield call(apiCall, `/domain/${action.payload.id}/assign_roles`, "put", action.payload.roles, getIdToken());
        toast.success("Roles are assigned to Domain successfully !");
    } catch ({message}) {
        yield put(getRoleFailure(message));
    }
}


export function* domainSaga() {
    yield takeLatest(getDomain().type, workGetDomains);
    yield takeLatest(addDomain().type, workAddDomain);
    yield takeLatest(editDomain().type, workEditDomain);
    yield takeLatest(deleteDomain().type, workDeleteDomain);
    yield takeLatest(assignRolesToDomain().type, workAssignRolesToDomain);
}
