import React, {useEffect} from 'react';
import {Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner,} from '@nextui-org/react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {addDomain, editDomain, setName} from "../../redux/reducers/DomainReducer";

function ModalDomainSave({isOpen, onClose, onOpenChange}) {

    const dispatch = useDispatch();
    const {
        register,
        reset,
        handleSubmit,
        formState: {
            errors
        },
    } = useForm();

    const {isSavedLoading, selectedDomain} = useSelector((state) => state.domainReducer);

    useEffect(() => {
        reset(selectedDomain)
    }, [selectedDomain])

    function submit(data) {
        delete data.domain_id
        if (selectedDomain.name === "") {
            dispatch(addDomain({data, close: onClose}));
        } else {
            dispatch(editDomain({data, id: selectedDomain.domain_id, close: onClose}))
        }
    }


    return (
        <Modal placement="center" isOpen={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            {selectedDomain ? <h3>Update Domain</h3> : <h3>Create Domain</h3>}
                        </ModalHeader>
                        <ModalBody>
                            {
                                <form id="form" onSubmit={handleSubmit(submit)}>
                                    <Input
                                        {...register('name', {required: true})}
                                        className={"mt-2"}
                                        radius={"none"}
                                        type="text"
                                        placeholder={"Domain name"}
                                        onChange={(e) => dispatch(setName(e.target.value))}
                                    />
                                    {errors.name && <p className="text-red-500 text-sm">Domain is required.</p>}
                                </form>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                Close
                            </Button>
                            <Button type="submit" form="form" color="primary">
                                Save {isSavedLoading && <Spinner color={"white"} size={"sm"}/>}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

export default ModalDomainSave;