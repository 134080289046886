import React from 'react';
import {Link} from "react-router-dom";
import ticketBoat from "../../assets/ticketboat.png";

function NotFound() {
    return (
        <div className={"flex bg-mainColor items-center flex-col gap-8 justify-center w-[100%] h-[100vh]"} >
            <h1 className={"text-6xl text-white font-bold"} > 404 Page Not Found</h1>
            <Link className={"h-[100px] flex bg-secondColor items-center rounded justify-center"} to={"/"}>
                <img src={ticketBoat} alt="Logo" className="w-1/2"/>
            </Link>
        </div>
    );
}

export default NotFound;