import React, {useEffect, useRef, useState} from 'react';
import {
    Avatar,
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Input
} from '@nextui-org/react';
import {toast} from 'react-toastify';
import ticketBoat from '../../assets/ticketboat.png';
import {useAuth0} from '@auth0/auth0-react';
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {RxHamburgerMenu} from "react-icons/rx";
import {toggle} from "../../redux/reducers/StyleReducer";


const Header = () => {

    const {
        loginWithRedirect,
        logout,
        user,
        isLoading,
    } = useAuth0();
    const scopes = ["openid,profile,roles"]
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [password, setNewPassword] = useState("")
    const {userRole} = useSelector(state => state.authReducer)
    const dispatch = useDispatch()

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const handleLogout = () => {
        logout({returnTo: process.env.REACT_APP_ALLOWED_CALL_BACK_URLS});
    };

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        window.addEventListener('click', handleClickOutside);

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);


    const handlePasswordChange = async () => {
        try {
            let token = localStorage.getItem("api_m_token")
            const accessToken = token && token
            const apiUrl = 'https://dev-e4i0y8hwtihzinzo.us.auth0.com/api/v2';
            const userId = user.sub;
            await axios.patch(`${apiUrl}/users/${userId}`, {password}, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${accessToken}`
                }
            });
            toast.success("password changed successfully !")
        } catch (error) {
            toast.error(`
            password should include 
            \nat least one Uppercase letter!
             at least 8 number
            `)
        }
    };
    return (
        <header
            className="header border-b-1 flex bg-mainColor sticky top-0 shadow-2xl justify-between items-center py-4 px-8 lg:px-2">
            <RxHamburgerMenu
                onClick={()=>dispatch(toggle())}
                fontSize={"30px"}
                color={"white"}
                className={"hidden sm:block cursor-pointer"}
            />
            <Link className="logo" to={"/"}>
                <img src={ticketBoat} alt="Logo" className="w-[150px]"/>
            </Link>
            <div className="relative" ref={dropdownRef}>
                {(!isLoading && user) ? (
                    <>
                        <button
                            onClick={toggleDropdown}
                            className="flex items-center text-white focus:outline-none"
                        >
                            <Avatar name={user.nickname}/>
                        </button>
                        {isDropdownOpen && (
                            <div
                                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="py-1" role="menu" aria-orientation="vertical"
                                     aria-labelledby="user-menu">
                                    {
                                        userRole === "ADMIN" && (<Link
                                                to={"/admin/user"}
                                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                                role="menuitem">
                                                Admin page
                                            </Link>
                                        )
                                    }
                                    <button
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                        role="menuitem"
                                        onClick={onOpen}
                                    >
                                        Update Password
                                    </button>
                                    <button
                                        onClick={() => handleLogout()}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                        role="menuitem"
                                    >
                                        Log Out
                                    </button>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        {!isLoading && (
                            <Button onClick={() => loginWithRedirect({
                                scope: scopes.join(" ")
                            })}>Login</Button>
                        )}
                    </>
                )}
            </div>

            <Modal placement={"center"} isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Update Password</ModalHeader>
                            <ModalBody>
                                <Input
                                    onChange={({target}) =>
                                        setNewPassword(target.value)}
                                    type="password"
                                    label="Password"
                                    placeholder="Enter your new password !"/>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Close
                                </Button>
                                <Button onClick={handlePasswordChange} color="primary">
                                    save changes
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

        </header>
    );
};

export default Header;