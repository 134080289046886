import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    reports: [],
    isLoading: false,
    isSavedLoading: false,
    isUpdateLoading: false,
    selectedReport: "",
    errorAdding: "",
    search: "",
    selectedReports: []
};

const reportSlice = createSlice({
    name: "report",
    initialState,
    reducers: {
        // Loading and Error reducers
        getReport: state => {
            state.isLoading = true;
            state.error = null;
        },
        setSelectedReport: (state, {payload}) => {
            state.selectedReport = payload
        },
        getReportSuccess: (state, {payload}) => {
            state.reports = payload;
            state.isLoading = false;
        },
        getReportsFailure: (state, {payload}) => {
            state.error = payload;
        },

        // Portal Reports reducers
        addToPortalReports: (state, {payload}) => {
            const userId = localStorage.getItem("clickedUser");
            if (userId && !state.portalReports.includes(payload)) {
                state.portalReports.push(payload);
            } else if (userId && state.portalReports.includes(payload)) {
                state.portalReports = state.portalReports.filter(item => item !== payload);
            }
        },
        clearPortalReport: state => {
            state.portalReports = [];
            state.removedReports = [];
        },
        saveReport: state => {
            state.removedReports = [];
            state.portalReports = [];
        },

        addReport: (state) => {
            state.isSavedLoading = true
        },
        addReportSuccess: (state) => {
            state.isSavedLoading = false
        },
        deleteReport: () => {
        },
        editReport: (state, action) => {
            state.isSavedLoading = true
        },
        getCategoryReports: (state) => {
            state.reports = []
            state.isLoading = true
        },
        setErrorAdding: (state, action) => {
            state.errorAdding = action.payload
        },
        setSearch: (state, action) => {
            state.search = action.payload
        },
        setSelectedReports: (state, action) => {
            state.selectedReports = action.payload
        }
    }
});

export const {
    getReport,
    addReport,
    getReportSuccess,
    getReportsFailure,
    deleteReport,
    editReport,
    saveReport,
    clearPortalReport,
    getCategoryReports,
    addReportSuccess,
    setSelectedReport,
    setErrorAdding,
    setSearch,
    setSelectedReports,

} = reportSlice.actions;

export default reportSlice.reducer;
