import React from 'react';
import {Pagination} from "@nextui-org/react";

function PaginationUniv({page, pages, setPage}) {
    return (
        <Pagination
            isCompact
            showControls
            showShadow
            classNames={{
                cursor:
                    "bg-[#4797CE] dark:from-default-300 dark:to-default-100 text-white font-bold",
            }}
            page={page}
            total={pages}
            onChange={(page) => setPage(page)}
        />
    );
}

export default PaginationUniv;