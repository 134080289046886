import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {
    Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Select, SelectItem, Spinner, Textarea
} from "@nextui-org/react";
import {addReport, editReport, setErrorAdding} from "../../redux/reducers/ReportReducer";
import {setSelectedCategoryId} from "../../redux/reducers/GroupReducer";
import {getCategory, setSelectedCategories} from "../../redux/reducers/CategoryReducer";
import {useNavigate, useParams} from "react-router-dom";

function ModalReportSave({isOpen, onClose, onOpenChange}) {
    const dispatch = useDispatch();
    const {id} = useParams()
    const {
        register, reset, handleSubmit, formState: {errors},
    } = useForm();

    const navigate = useNavigate()
    const {categories} = useSelector(state => state.categoryReducer)
    const {categoryId} = useSelector(state => state.roleReducer)
    const {idToken} = useSelector(state => state.authReducer)

    const {
        selectedReport, isSavedLoading, errorAdding
    } = useSelector((state) => state.reportReducer);


    useEffect(() => {
        dispatch(getCategory())
    }, [idToken])

    useEffect(() => {
        reset(selectedReport)
    }, [selectedReport])

    function submit(data) {
        delete data.report_id
        if (!categoryId) {
            dispatch(setErrorAdding("Category Id is required !"))
            return
        }
        if (selectedReport.title !== "") {
            dispatch(editReport({
                data: {...data, category_id: categoryId},
                id: selectedReport.report_id,
                close: onClose,
            }));
        } else {
            dispatch(addReport({
                data: {...data, category_id: categoryId},
                close: onClose
            }));
        }
        dispatch(setSelectedCategories([]))
        navigate("/admin/report")
    }

    return (<Modal placement="center" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
            {(onClose) => (<>
                <ModalHeader className="flex flex-col gap-1">
                    {selectedReport.title ? <h3>Update Report</h3> : <h3>Add Report</h3>}
                </ModalHeader>
                <ModalBody>
                    {<form id="form" onSubmit={handleSubmit(submit)}>
                        <Input
                            {...register('title', {required: true})}
                            className={"mt-2"}
                            radius={"sm"}
                            type="text"
                            placeholder={"Title"}
                        />
                        {errors.title && <p className="text-red-500 text-sm">Title is required.</p>}
                        <Input
                            {...register('link', {required: true})}
                            className={"mt-2"}
                            radius={"sm"}
                            type="text"
                            placeholder={"Link"}
                        />
                        {errors.link && <p className="text-red-500 text-sm">Link is required.</p>}

                        <Select
                            key={""}
                            radius={"sm"}
                            required={true}
                            label="Choose category"
                            defaultSelectedKeys={categories.some(cat => cat.category_id === categoryId) ? [categoryId] : []}
                            className="mt-3"
                            onChange={(e) => dispatch(setSelectedCategoryId(e.target.value))}
                            color={"#fff"}>
                            {categories.map((ct) => (<SelectItem key={ct.category_id} value={ct.category_id}>
                                {ct.title}
                            </SelectItem>))}
                        </Select>
                        <p className="text-red-500 text-sm">{errorAdding}</p>

                        <Textarea
                            {...register('description')}
                            className={"mt-2"}
                            radius={"sm"}
                            type="text"
                            placeholder={"Description"}
                        />
                    </form>}
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" variant="light" onPress={onClose}>
                        Close
                    </Button>
                    <Button type="submit" form="form" color="primary">
                        Save {isSavedLoading && <Spinner color={"white"} size={"sm"}/>}
                    </Button>
                </ModalFooter>
            </>)
            }
        </ModalContent>
    </Modal>)
        ;
}

export default ModalReportSave;