import React from 'react';
import {Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader} from "@nextui-org/react";
import * as PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";


function ModalAdminDelete({isOpen,itemTitle,name,onClose,id,deleteItem}) {
    ModalFooter.propTypes = {children: PropTypes.node};
    const dispatch = useDispatch()

    return (
        <Modal placement={"center"} isOpen={isOpen} onClose={onClose}>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">Delete {name}</ModalHeader>
                        <ModalBody>
                            <p>
                                Are you sure you want to delete {itemTitle}?
                            </p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                Close
                            </Button>
                            <Button onClick={() => dispatch(deleteItem({id: id, close: onClose}))}
                                    color="primary" onPress={onClose}>
                                save
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

export default ModalAdminDelete;