import React, {useEffect, useMemo} from 'react';
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    getKeyValue,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    useDisclosure
} from "@nextui-org/react";
import ModalDomainSave from "../../modal/ModalDomainSave";
import PaginationUniv from "../../uiComponents/Pagination";
import {BsThreeDotsVertical} from "react-icons/bs";
import {AiOutlineEdit} from "react-icons/ai";
import {MdOutlineDeleteOutline} from "react-icons/md";
import ModalConfirmDelete from "../../modal/ModalConfirmDelete";
import {
    assignRolesToDomain,
    deleteDomain,
    getDomainRoles,
    setDomainRoles, setIsAssignRole,
    setSelectedDomain
} from "../../../redux/reducers/DomainReducer";
import {useDispatch, useSelector} from "react-redux";
import {getRole, setUserRoles} from "../../../redux/reducers/GroupReducer";
import ModalAssignRoles from "../../modal/ModalAssignRoles";
import {setIsAssigned} from "../../../redux/reducers/StyleReducer";

function DomainList({domains, isLoading}) {


    const dispatch = useDispatch()
    const [page, setPage] = React.useState(1);
    const rowsPerPage = 9; // Change to 10 as per your requirement
    const {onOpen, onClose, isOpen, onOpenChange} = useDisclosure()
    const loadingState = isLoading ? "loading" : "idle";
    const {onOpen: onOpenConfirm, onClose: onCloseConfirm, isOpen: isOpenConfirm} = useDisclosure()
    const {onOpen: onOpenAssign, onClose: onCloseAssign, isOpen: isOpenAssign} = useDisclosure()
    const {selectedDomain} = useSelector(state => state.domainReducer)

    const paginatedDomains = useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage;
        return domains?.slice(startIndex, startIndex + rowsPerPage);
    }, [domains, page]);

    const pages = useMemo(() => {
        return domains?.length ? Math.ceil(domains.length / rowsPerPage) : 0;
    }, [domains?.length, rowsPerPage]);

    function handleAssign(item) {
        dispatch(setSelectedDomain(item))
        dispatch(setIsAssigned())
        onOpenAssign()
    }

    useEffect(() => {
        if (!isOpenAssign) {
            dispatch(setSelectedDomain(""))
            dispatch(setUserRoles([]))
        }
    }, [isOpenAssign]);


    return (
        <div>
            <Button onClick={() => {
                dispatch(setSelectedDomain({name: ""}))
                onOpen()
            }}
                    className={"mb-5 float-right bg-secondColor text-white font-bold"}
                    radius={"sm"}>
                Add Domain
            </Button>
            <Table
                aria-label="Example table with client async pagination"
                bottomContent={
                    pages > 0 ? (
                        <div className="flex w-full justify-center">
                            <PaginationUniv setPage={setPage} page={page} pages={pages}/>
                        </div>
                    ) : null
                }
            >
                <TableHeader>
                    <TableColumn key="name">Name</TableColumn>
                    <TableColumn key="actions">Actions</TableColumn>
                </TableHeader>
                <TableBody
                    items={paginatedDomains ?? []}
                    loadingContent={<Spinner/>}
                    loadingState={loadingState}
                >
                    {(item) => (
                        <TableRow key={item?.domain_id}>
                            {(columnKey) => {
                                if (columnKey !== "actions") {
                                    return (
                                        <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                                    );
                                } else {
                                    return <TableCell>
                                        <div className="relative">
                                            <Dropdown className="bg-background border-1 border-default-200">
                                                <DropdownTrigger>
                                                    <Button isIconOnly radius="full" variant="light">
                                                        <BsThreeDotsVertical size={"24px"}/>
                                                    </Button>
                                                </DropdownTrigger>
                                                <DropdownMenu aria-label={"menu"}>
                                                    <DropdownItem onClick={() => handleAssign(item)}>
                                                        Assign Role
                                                    </DropdownItem>

                                                    <DropdownItem onClick={() => {
                                                        dispatch(setSelectedDomain(item))
                                                        dispatch(setIsAssignRole(false))
                                                        onOpen()
                                                    }}>
                                                        <div className={'flex   gap-x-2  items-center justify-start'}>
                                                            <AiOutlineEdit/>
                                                            Edit
                                                        </div>
                                                    </DropdownItem>

                                                    <DropdownItem onClick={() => {
                                                        dispatch(setSelectedDomain(item))
                                                        dispatch(setIsAssignRole(false))
                                                        onOpenConfirm()
                                                    }}>
                                                        <div className={'flex gap-x-2 items-center justify-start'}>
                                                            <MdOutlineDeleteOutline/>
                                                            Delete
                                                        </div>
                                                    </DropdownItem>

                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </TableCell>
                                }
                            }}
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <ModalDomainSave
                isOpen={isOpen}
                onClose={onClose}
                onOpenChange={onOpenChange}
            />

            <ModalConfirmDelete
                isOpen={isOpenConfirm}
                name={"Domain"}
                itemTitle={selectedDomain.name}
                deleteItem={deleteDomain}
                id={selectedDomain.domain_id}
                onClose={onCloseConfirm}
            />
            <ModalAssignRoles
                id={selectedDomain.domain_id}
                isOpen={isOpenAssign}
                onClose={onCloseAssign}
                assignRolesToItem={assignRolesToDomain}
                getAssignedRoles={getDomainRoles}
                setAssignedRoles={setUserRoles}
                setSelectedItem={setSelectedDomain}
                selectedItem={selectedDomain.name}
            />
        </div>
    );
}

export default DomainList;
