import React, {useEffect} from 'react';
import {
    Button,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Spinner,
    Textarea
} from "@nextui-org/react";
import {useDispatch, useSelector} from "react-redux";
import {addCategory, updateCategory} from "../../redux/reducers/CategoryReducer";
import {useForm} from "react-hook-form";

function ModalCategorySave({isOpen, onOpenChange,onClose}) {

    const dispatch = useDispatch()
    const {handleSubmit, register, reset, formState: {errors}} = useForm()
    const {selectedCategory, isSavedLoading} = useSelector(state => state.categoryReducer)

    useEffect(()=>{
       reset(selectedCategory)
    },[selectedCategory])

    function saveCategory(data) {
        delete data.category_id
        if (selectedCategory.title !== "") {
            dispatch(updateCategory({data, categoryId: selectedCategory.category_id, onClose}))
        } else {
            dispatch(addCategory({data, onClose}))
        }
    }

    return (
        <Modal placement={"center"} isOpen={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            {selectedCategory?.title ? "Update Category" : "Add Category"}
                        </ModalHeader>
                        <ModalBody>
                            <form id={"form"} onSubmit={handleSubmit(saveCategory)}>
                                <Input
                                    {...register("title", {required: "Title is required"})}
                                    type="text"
                                    placeholder={"Title here"}
                                    label={"Title"}
                                />
                                {errors.title && (
                                    <p className="text-red-500 text-sm">{errors.title.message}</p>
                                )}
                                <Textarea
                                    className={"mt-3"}
                                    {...register("description")}
                                    label="Description"
                                    labelPlacement={"outside"}
                                    placeholder="Enter your description"
                                />
                            </form>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                Close
                            </Button>
                            <Button type={"submit"} form={"form"} color="primary">
                                Save
                                {isSavedLoading && <Spinner size={"sm"} color={"white"}/>}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

export default ModalCategorySave;