import React from 'react';
import ReactLoading from 'react-loading';

function Loading({type, color}) {
    return (
        <div className={"w-[100%] h-[97vh] flex flex-col items-center justify-center"} >
            <ReactLoading type={"spinningBubbles"} color={"#00365C"} height={120} width={120}/>
        </div>
    );
}

export default Loading;