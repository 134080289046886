import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    users: [],
    selectedUser: "",
    isLoading: false,
    error: null,
    search: "",
    isChangingRole: true,
    isUserSaved: true,
    emailVerified: false,
    userCreationMessage: ""
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        // Loading and Error reducers
        getUsers: state => {
            state.isLoading = true;
            state.error = null;
        },
        addUserStart: (state, action) => {
            state.isUserSaved = true
        },
        addUserSuccessOrFailure: (state, {payload}) => {
            state.isUserSaved = false
        },
        getUserSuccess: (state, {payload}) => {
            state.users = payload;
            state.isLoading = false;
        },
        getUserFailure: (state, {payload}) => {
            state.error = payload;
            state.isLoading = false
        },

        // Clicked User reducers
        setSelectedUser: (state, {payload}) => {
            state.selectedUser = payload;
        },
        setSearch: (state, action) => {
            state.search = action.payload
        },
        setSmallSearch: (state, action) => {
            state.smallSearch = action.payload
        },
        deleteUser: (state, action) => {
        },
        changeRole: (state) => {
            state.isChangingRole = false
            console.log("goo1")
        },
        removeAdminRole: (state, action) => {
            state.isChangingRole = false
        },
        setIsChangingRole: (state, action) => {
            state.isChangingRole = true;
        },
        setEmailVerified: (state, action) => {
            state.emailVerified = action.payload
        },
        resUser: (state, {payload}) => {
            state.userCreationMessage = payload
        }
    }
});

export const {
    getUserSuccess,
    getUsers,
    getUserFailure,
    setSearch,
    setSelectedUser,
    deleteUser,
    changeRole,
    removeAdminRole,
    setIsChangingRole,
    addUserStart,
    addUserSuccessOrFailure,
    setEmailVerified,
    resUser
} = userSlice.actions;

export default userSlice.reducer;
