import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    categories: [],
    isLoading: false,
    isSavedLoading: false,
    selectedCategory: "",
    error: "",
    selectedCategories: []
};

const slice = createSlice({
    name: "category",
    initialState,
    reducers: {
        getCategory(state) {
            state.isLoading = true;
            state.error = null;
        },
        getCategorySuccess(state, action) {
            state.categories = action.payload
            state.isLoading = false
        },
        getCategoryFailure(state, action) {
            state.error = action.payload
        },
        addCategory: (state) => {
            state.isSavedLoading = true
        },
        deleteCategory: (state) => {
        },
        savedCategorySuccessfully: (state) => {
            state.isSavedLoading = false
        },
        updateCategory: (state, action) => {
            state.isSavedLoading = true
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload
        },
        setError: (state, action) => {
            state.error = action.payload
        },
        setSelectedCategories: (state, action) => {
            state.selectedCategories = action.payload
        }
    }
});

export const {
    getCategory,
    getCategoryFailure,
    getCategorySuccess,
    addCategory,
    deleteCategory,
    updateCategory,
    setSelectedCategory,
    savedCategorySuccessfully,
    setError,
    setSelectedCategories
} = slice.actions;
export default slice.reducer;
