import {call, put, takeLatest, delay} from "redux-saga/effects";
import {
    addUserStart,
    addUserSuccessOrFailure,
    changeRole,
    deleteUser,
    getUserFailure,
    getUsers,
    getUserSuccess, removeAdminRole, resUser, setIsChangingRole
} from "../../reducers/UserReducer";
import {userApiCall} from "../../apiCall/userApiCall";
import {apiCall} from "../../apiCall/apiCall";
import {store} from "../../store";
import {toast} from "react-toastify";

const getIdToken = () => store.getState().authReducer.idToken;

function* fetchUsersAndRoles() {
    try {
        const usersResponse = yield call(userApiCall, `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users?q=logins_count:0`, "get");
        const usersNeverLoggedRes = yield call(userApiCall, `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users`, "get");
        const users = usersResponse.data;
        const usersNeverLogged = usersNeverLoggedRes.data;
        yield put(getUserSuccess([...users, ...usersNeverLogged]));
    } catch (error) {
        yield put(getUserFailure(error.message));
    } finally {
        yield put(setIsChangingRole())
    }
}


export function* workDeleteUser(action) {
    let userId = action.payload.id
    try {
        yield call(userApiCall, `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${userId}`, "delete");
        toast.success("User deleted successfully");
        yield delay(2000)
        yield put(getUsers())
    } catch ({message}) {
        yield put(getUserFailure(message));
    }
}

export function* workAddUser(action) {
    try {
        yield call(userApiCall, `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/users`, "post",
            {
                email: action.payload.email,
                password: action.payload.password,
                username: action.payload.username,
                email_verified: action.payload.emailVerified,
                connection: 'Username-Password-Authentication'
            });
        toast.success("User created successfully !");
        yield delay(2000)
        yield put(getUsers())
        yield put(resUser(""));
        action.payload.onClose();
    } catch (err) {
        const errorMessage = err.response?.data?.message || "An unexpected error occurred";
        toast.error(errorMessage);
        yield put(getUserFailure(err));
    } finally {
        yield put(addUserSuccessOrFailure())
    }
}


export function* workChangeRole(action) {
    let {userId, role} = action.payload
    try {
        yield call(apiCall, `/auth/assign_role/${userId}`, "patch", role, getIdToken());
        yield delay(2000)
        yield put(getUsers())
    } catch ({message}) {
        yield put(getUserFailure(message));
    }
}

export function* workRemoveAdminRole(action) {
    const {userId, roleId} = action.payload
    try {
        yield call(apiCall, `/auth/${userId}/remove_role/${roleId}`, "delete", null, getIdToken());
        yield delay(2000)
        yield put(getUsers())
    } catch ({message}) {
        yield put(getUserFailure(message));
    }
}

export function* userSaga() {
    yield takeLatest(addUserStart().type, workAddUser);
    yield takeLatest(removeAdminRole().type, workRemoveAdminRole);
    yield takeLatest(changeRole().type, workChangeRole);
    yield takeLatest(getUsers().type, fetchUsersAndRoles);
    yield takeLatest(deleteUser().type, workDeleteUser)
}