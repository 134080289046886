import reactDOM from "react-dom/client"
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {NextUIProvider} from "@nextui-org/react";
import {store} from "./redux/store";
import "./index.css"
import Auth0ProviderWithHistory from "./auth0Provider";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


let root = reactDOM.createRoot(document.getElementById("root"))


root.render(
    <Provider store={store}>
        <BrowserRouter>
            <NextUIProvider>
                <Auth0ProviderWithHistory>
                    <App/>
                    <ToastContainer/>
                </Auth0ProviderWithHistory>
            </NextUIProvider>
        </BrowserRouter>
    </Provider>
)

