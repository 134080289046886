import React, {useEffect} from 'react';
import UserList from "../../../components/admin/user/UserList";
import {useDispatch, useSelector} from "react-redux";
import {getUsers, resUser} from "../../../redux/reducers/UserReducer";
import {Button, useDisclosure} from "@nextui-org/react";
import ModalUser from "../../../components/modal/ModalUser";


function User() {

    const {users, isLoading, search, userCreationMessage} = useSelector(state => state.userReducer)
    const dispatch = useDispatch()

    const {onOpen, onClose, isOpen, onOpenChange} = useDisclosure()

    useEffect(() => {
        dispatch(getUsers())
    }, []);

    useEffect(() => {
        dispatch(resUser(""))
    }, [onOpen])

    return (
        <div className={"user border"}>

            <Button onClick={() => {
                onOpen()
            }}
                    className={"mb-5 float-right bg-secondColor text-white font-bold"}
                    radius={"sm"}>
                Add User
            </Button>

            <ModalUser message={userCreationMessage} onOpenChange={onOpenChange} isOpen={isOpen} onClose={onClose}/>

            <UserList
                users={users.filter(user =>
                    user.email.toLowerCase().includes(search.toLowerCase())
                    ||
                    user.name.toLowerCase().includes(search.toLowerCase())
                )}
                isLoading={isLoading}
            />
        </div>
    );
}

export default User;

