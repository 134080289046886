import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    reports: [],
    reportId: "",
    embedReportId: "",
    isLoading: false,
    isLoadingSuccess: false,
    error: null,
    embedKey: 0,
    embedTokenObj: {},
    accordionKeys: []
};

const slice = createSlice({
    name: "portal",
    initialState,
    reducers: {
        setIsLoadingSuccess: (state, {payload}) => {
            state.isLoadingSuccess = payload
        },
        setAccordionKeys: (state, {payload}) => {
            state.accordionKeys = payload
        },
        getUserReports: state => {
            state.isLoading = true;
            state.error = null;
            state.isLoadingSuccess = true
        },
        getUserReportSuccess: (state, {payload}) => {
            if (payload instanceof Set) {
                payload = Array.from(payload);
            }
            state.reports = Array.from(payload);
            state.isLoading = false;
            state.isLoadingSuccess = false
        },
        getUserFailure: (state, {payload}) => {
            state.error = payload;
        },
        saveReportId: (state, {payload}) => {
            state.reportId = payload;
        },
        setEmbedKey: state => {
            state.embedKey += 1;
        },
        getEmbedToken: state => {
            state.isLoading = true;
            state.error = null;
        },
        setEmbedToken: (state, {payload}) => {
            state.embedTokenObj = payload;
            localStorage.setItem("embed_token", JSON.stringify(payload));
            state.isLoading = false;
        },
        setEmbedReportId: (state, {payload}) => {
            const link = payload;
            state.embedReportId = link.substring(
                link.indexOf("/reports") + 9,
                link.indexOf("/ReportSection")
            );
        },
        setLinkToEmbedReportId: (state, {payload}) => {
            state.embedReportId = payload
        },
        clearSavedReports: state => {
            state.reports = [];
        },
        changeLoading: state => {
            state.isLoading = true;
        },
        clearReportId: (state) => {
            state.reportId = ""
        },
        assignRolesToUser: (state, action) => {
            state.isLoading = true
        },
        setFalseToIsLoading: (state) => {
            state.isLoading = false
        }
    }
});

export const {
    getUserReports,
    getUserFailure,
    getUserReportSuccess,
    saveReportId,
    setEmbedKey,
    getEmbedToken,
    setEmbedToken,
    setEmbedReportId,
    changeLoading,
    setLinkToEmbedReportId,
    clearReportId,
    assignRolesToUser,
    setFalseToIsLoading,
    setAccordionKeys,
    setIsLoadingSuccess
} = slice.actions;

export default slice.reducer;
