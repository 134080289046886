import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {
    clearReportId,
    getUserReports,
    saveReportId,
    setEmbedReportId,
    setLinkToEmbedReportId
} from "../../redux/reducers/PortalReducer";
import {useDispatch, useSelector} from "react-redux";
import {Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button} from '@chakra-ui/react';
import {useParams} from "react-router-dom";
import SkeletonLoading from "../loading/SkeletonLoading";
import InputUniv from "../uiComponents/Input";


function Sidebar(props) {

    const {user} = useAuth0()
    const dispatch = useDispatch()
    const {idToken} = useSelector(state => state.authReducer)
    const {isActive} = useSelector(state => state.styleReducer)
    const {reports, accordionKeys, reportId, isLoadingSuccess, isLoading} = useSelector(state => state.portalReducer)
    const {id} = useParams();
    const {link} = useParams();
    const [inputValue, setInputValue] = useState('');
    const [filteredReports, setFilteredReports] = useState([])
    const [expandedIndices, setExpandedIndices] = useState([]);


    useEffect(() => {
        dispatch(getUserReports({userId: `client/${user.email}`}))
    }, [user, idToken])

    useEffect(() => {
        if (id && link) {
            dispatch(saveReportId(id))
            dispatch(setLinkToEmbedReportId(link))
            props.navigate(`/report/${id}/${link}`)
        } else {
            dispatch(clearReportId())
        }
    }, [reports])

    useEffect(() => {
        filterByReportTitle(inputValue)
    }, [inputValue, reports, accordionKeys]);

    useEffect(() => {
        setExpandedIndices(reports.map((_, index) => index));
    }, [reports]);

    function filterByReportTitle(inputValue) {
        if (inputValue) {
            let filteredData;
            let filteredCategoryReport;
            let tempReports = [];
            for (let categoryReport of reports) {
                filteredData = categoryReport.reports.filter(report => report.title.toLowerCase()
                    .includes(inputValue.toLowerCase()))
                if (filteredData.length > 0) {
                    filteredCategoryReport = {...categoryReport, reports: filteredData}
                    tempReports.push(filteredCategoryReport)
                }
            }
            setFilteredReports([...tempReports])
        } else {
            setFilteredReports([...reports])
        }
    }


    function setReportData(report) {
        dispatch(saveReportId(report.report_id))
        dispatch(setEmbedReportId(report.link))
        props.navigate(`/report/${report.report_id}/${report.link.substring(
            report.link.indexOf("/reports") + 9,
            report.link.indexOf("/ReportSection"))}`)
    }

    const toggleExpandAll = () => {
        if (expandedIndices.length === filteredReports.length) {
            setExpandedIndices([]);
        } else {
            setExpandedIndices(filteredReports.map((_, index) => index));
        }
    };


    return (
        (
            <div className={`relative sm:left-[-100%] sm:transition-all ${isActive && 'sm:left-[0%] z-20'}`}>
                <div className={'w-[300px] sm:absolute p-3 bg-mainColor shadow-blue-2xl h-[100vh] overflow-y-auto'}>
                    {
                        reports.length > 0 && <>
                            <InputUniv
                                inputValue={inputValue}
                                onChange={setInputValue}
                            />
                            <div className="flex justify-end py-2">
                                <Button onClick={toggleExpandAll}
                                        colorScheme="blue"
                                        variant="outline"
                                        className={"text-gray-100"}
                                >
                                    {expandedIndices.length === filteredReports.length ? 'Collapse All' : 'Expand All'}
                                </Button>
                            </div>
                        </>
                    }
                    {!isLoadingSuccess || !isLoading ? (
                        <Accordion allowMultiple index={expandedIndices} onChange={setExpandedIndices}>
                            {reports.length > 0 ?
                                filteredReports.map((cy) => (
                                    <>
                                        <AccordionItem className={"mt-3"} key={cy.category_id}>
                                            <h2>
                                                <AccordionButton>
                                                    <Box className={"text-gray-200 mb-2"} as={"span"} flex="1"
                                                         textAlign="left">
                                                        {cy.title}
                                                    </Box>
                                                    <AccordionIcon color={"white"}/>
                                                </AccordionButton>
                                            </h2>
                                            <hr/>
                                            <AccordionPanel>
                                                {cy.reports.map((rp) => (
                                                    <div
                                                        key={rp.report_id}
                                                        onClick={() => {
                                                            setReportData(rp)
                                                        }}
                                                        className={`cursor-pointer rounded-sm hover:bg-hoverColor p-2 text-white ${
                                                            reportId === rp.report_id ? 'bg-secondColor' : ''
                                                        }`}
                                                    >
                                                        {rp.title}
                                                    </div>
                                                ))}
                                            </AccordionPanel>
                                        </AccordionItem>
                                    </>
                                ))
                                : <p className={"text-white mt-3"}>You don't have any assigned reports!</p>
                            }
                        </Accordion>
                    ) : (
                        <SkeletonLoading count={4}/>
                    )}
                </div>
            </div>
        ));
}

export default Sidebar;

