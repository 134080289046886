import {all} from "redux-saga/effects"
import {reportSaga} from "./reportSaga/reportSaga";
import {userSaga} from "./userSaga/userSaga";
import {portalSaga} from "./portalSaga/portalSaga";
import {groupSaga} from "./groupSaga/groupSaga";
import {categorySaga} from "./categorySaga/categorySaga";
import {domainSaga} from "./domainSaga/domainSaga";

export default function* rootSaga() {
    yield all(
        [
            reportSaga(),
            userSaga(),
            portalSaga(),
            groupSaga(),
            categorySaga(),
            domainSaga()
        ]
    )
}