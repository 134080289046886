import React, {useEffect, useMemo} from 'react';
import {
    Button,
    getKeyValue,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Pagination,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow
} from "@nextui-org/react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedUser} from "../../redux/reducers/UserReducer";
import {getCategoryReports, getReport, setSelectedReports} from "../../redux/reducers/ReportReducer";
import {assignReportToGroup, getRoleReports} from "../../redux/reducers/GroupReducer";

function ModalAssignReport({role, onClose, isOpen}) {

    const dispatch = useDispatch()
    const [page, setPage] = React.useState(1);
    const rowsPerPage = 6;
    const {reports, isLoading, selectedReports} = useSelector(state => state.reportReducer)
    const {isUpdateLoading: isAssignLoading} = useSelector(state => state.roleReducer)
    const {isAssigned} = useSelector(state => state.styleReducer)

    useEffect(() => {
        if (role.group_id) {
            dispatch(getCategoryReports({search: ""}))
            dispatch(getRoleReports(role.group_id))
        }
    }, [isAssigned])

    const paginatedReports = useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage;
        return reports?.slice(startIndex, startIndex + rowsPerPage);
    }, [reports, page]);

    const pages = useMemo(() => {
        return reports?.length ? Math.ceil(reports.length / rowsPerPage) : 0;
    }, [reports?.length, rowsPerPage]);
    const loadingState = isLoading ? "loading" : "idle";

    function setSelected(e) {
        dispatch(setSelectedReports(e))
    }

    function assignRoles() {
        dispatch(
            assignReportToGroup({
                groupId: role.group_id,
                data: selectedReports === "all" ?
                    reports.map(item => item.report_id) :
                    Array.from(selectedReports)
            }))
    }

    return (<Modal placement={"center"} isOpen={isOpen} onClose={onClose}>
        <ModalContent>
            {(onClose) => (<>
                <ModalHeader className="flex flex-col gap-1">
                    <p className={"text-800"}> Assign Report to {role.title}</p>
                </ModalHeader>
                <ModalBody>
                    <Table
                        aria-label="Example table with client async pagination"
                        selectionMode="multiple"
                        selectedKeys={selectedReports}
                        onSelectionChange={setSelected}
                        bottomContent={pages > 0 ? (<div className="flex w-full justify-center">
                            <Pagination
                                isCompact
                                showControls
                                showShadow
                                color="primary"
                                page={page}
                                total={pages}
                                onChange={(page) => setPage(page)}
                            />
                        </div>) : null}
                    >
                        <TableHeader>
                            <TableColumn key="title">Title</TableColumn>
                        </TableHeader>
                        <TableBody
                            items={paginatedReports ?? []}
                            loadingContent={<Spinner/>}
                            loadingState={loadingState}
                        >
                            {(item) => (<TableRow key={item?.report_id}>
                                {(columnKey) => {
                                    return <TableCell>{getKeyValue(item, columnKey)}</TableCell>;
                                }}
                            </TableRow>)}
                        </TableBody>
                    </Table>

                </ModalBody>
                <ModalFooter>
                    <Button color="danger" variant="light"
                            onPress={() => {
                                onClose();
                            }}>
                        Cancel
                    </Button>
                    <Button onClick={assignRoles} color="primary" variant="light">
                        Assign
                        {isAssignLoading && <Spinner size={"sm"} color={"white"}/>}
                    </Button>
                </ModalFooter>
            </>)}
        </ModalContent>
    </Modal>);
}

export default ModalAssignReport;