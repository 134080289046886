import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getRole} from "../../../redux/reducers/GroupReducer";
import RoleList from "../../../components/admin/role/GroupList";

function Role() {

    const dispatch = useDispatch()
    const {roles,isLoading} = useSelector(state=>state.roleReducer)
    const {idToken } = useSelector(state=>state.authReducer)

    useEffect(()=>{
        dispatch(getRole())
    },[idToken])


    return (
        <div>
            <RoleList roles={roles} isLoading={isLoading} />
        </div>
    );
}

export default Role;

