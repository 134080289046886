import React from 'react';
import Sidebar from "../../components/admin/sidebar/Sidebar";
import {Outlet} from "react-router-dom";

function Admin() {

    return (
        <div className={"flex"}>
            <Sidebar/>
           <div className="container bg-gray-200 px-5 pt-7">
               <Outlet/>
           </div>
        </div>
    );
}

export default Admin;


