import React, {useEffect} from 'react';
import DomainList from "../../components/admin/domain/DomainList";
import {useDispatch, useSelector} from "react-redux";
import {getDomain} from "../../redux/reducers/DomainReducer";

function Domain() {

    const dispatch = useDispatch()
    const {domains, isLoading} = useSelector(state => state.domainReducer)

    useEffect(() => {
        dispatch(getDomain())
    }, []);


    return (
        <div>
            <DomainList domains={domains} isLoading={isLoading}/>
        </div>
    );
}

export default Domain;