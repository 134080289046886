import React, {useMemo} from "react";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownTrigger,
    getKeyValue,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow,
    useDisclosure
} from "@nextui-org/react";
import {BsThreeDotsVertical} from "react-icons/bs";
import ModalConfirmDelete from "../../modal/ModalConfirmDelete";
import {deleteCategory, setSelectedCategory} from "../../../redux/reducers/CategoryReducer";
import {useDispatch, useSelector} from "react-redux";
import ModalCategorySave from "../../modal/ModalCategorySave";
import PaginationUniv from "../../uiComponents/Pagination";
import {MdOutlineDeleteOutline} from "react-icons/md"
import {AiOutlineEdit} from 'react-icons/ai'
import {Link} from "react-router-dom";

function CategoryList({categories, isLoading}) {

    const [page, setPage] = React.useState(1);
    const rowsPerPage = 9; // Change to 10 as per your requirement
    const {onOpen: onOpenConfirm, onClose: onCloseConfirm, isOpen: isOpenConfirm} = useDisclosure()
    const {onOpen, onClose, isOpen, onOpenChange} = useDisclosure()
    const dispatch = useDispatch()
    const {selectedCategory} = useSelector(state => state.categoryReducer)


    const paginatedCategories = useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage;
        return categories?.slice(startIndex, startIndex + rowsPerPage);
    }, [categories, page]);

    const pages = useMemo(() => {
        return categories?.length ? Math.ceil(categories.length / rowsPerPage) : 0;
    }, [categories?.length, rowsPerPage]);

    function handleDelete(item) {
        onOpenConfirm()
        dispatch(setSelectedCategory(item))
    }

    const loadingState = isLoading ? "loading" : "idle";

    function handleEdit(item) {
        onOpen()
        dispatch(setSelectedCategory(item))
    }

    return (
        <div>
            <Button onClick={() => {
                onOpen()
                dispatch(setSelectedCategory({title: "", description: ""}))
            }}
                    className={"mb-5 float-right bg-secondColor text-white font-bold"}
                    radius={"sm"}>
                Add Category
            </Button>
            <Table
                aria-label="Example table with client async pagination"
                bottomContent={
                    pages > 0 ? (
                        <div className="flex w-full justify-center">
                            <PaginationUniv setPage={setPage} page={page} pages={pages}/>
                        </div>
                    ) : null
                }
            >
                <TableHeader>
                    <TableColumn key="title">Title</TableColumn>
                    <TableColumn key="description">Description</TableColumn>
                    <TableColumn key="actions">Actions</TableColumn>
                </TableHeader>
                <TableBody
                    items={paginatedCategories ?? []}
                    loadingContent={<Spinner/>}
                    loadingState={loadingState}
                >
                    {(item) => (
                        <TableRow key={item?.category_id}>
                            {(columnKey) => {
                                if (columnKey === "title") {
                                    return (
                                        <TableCell>
                                            <Link
                                                to={`/admin/report/${item.category_id}`}
                                                className={"underline text-blue-500 hover:text-blue-600"}
                                            >
                                                {getKeyValue(item, columnKey)}
                                            </Link>
                                        </TableCell>
                                    )
                                } else if (columnKey !== "actions") {
                                    return (
                                        <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                                    );
                                } else {
                                    return <TableCell>
                                        <div className="relative">
                                            <Dropdown className="bg-background border-1 border-default-200">
                                                <DropdownTrigger>
                                                    <Button isIconOnly radius="full" variant="light">
                                                        <BsThreeDotsVertical size={"24px"}/>
                                                    </Button>
                                                </DropdownTrigger>
                                                <DropdownMenu aria-label={"menu"}>
                                                    <DropdownItem onClick={() => handleEdit(item)}>
                                                        <div className={'flex gap-x-2  items-center justify-start'}>
                                                            <AiOutlineEdit/>
                                                            Edit
                                                        </div>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => handleDelete(item)}>
                                                        <div className={'flex gap-x-2 items-center justify-start'}>
                                                            <MdOutlineDeleteOutline/>
                                                            Delete
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </TableCell>
                                }
                            }}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <ModalConfirmDelete
                deleteItem={deleteCategory}
                isOpen={isOpenConfirm}
                onClose={onCloseConfirm}
                name={selectedCategory.title}
                id={selectedCategory.category_id}
            />
            <ModalCategorySave
                isOpen={isOpen}
                onClose={onClose}
                onOpenChange={onOpenChange}
            />
        </div>
    );
}

export default CategoryList;
