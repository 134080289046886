import {useNavigate} from "react-router-dom";
import {Auth0Provider} from "@auth0/auth0-react";


const Auth0ProviderWithHistory = ({children}) => {

    const navigate = useNavigate()

    const onRedirectCallBack = (appState) => {
        navigate(appState?.returnTo || window.location.pathname)
    }

    return <Auth0Provider
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        redirectUri={window.location.origin}
        onRedirectCallback={onRedirectCallBack}
    >
        {children}
    </Auth0Provider>

}

export default Auth0ProviderWithHistory