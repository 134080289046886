import {call, takeLatest, put} from "redux-saga/effects";
import {apiCall} from "../../apiCall/apiCall";
import {
    addReport,
    getReportsFailure,
    getReportSuccess,
    deleteReport,
    editReport,
    saveReport, getCategoryReports, addReportSuccess, setErrorAdding,
} from "../../reducers/ReportReducer";
import {toast} from "react-toastify";
import {getEmbedToken, setEmbedToken} from "../../reducers/PortalReducer";
import {store} from "../../store";

const getIdToken = () => store.getState().authReducer.idToken;

export function* workGetCategoryReports(action) {
    const idToken = getIdToken();
    if (idToken) {
        try {
            let search = action?.payload.search ? action?.payload.search : ""
            let categoryId = action?.payload?.categoryId ? action?.payload.categoryId : []
            const res = yield call(apiCall, `/reports/category?category_ids=${categoryId.join(",")}&search=${search}`, "get", null, idToken);
            yield put(getReportSuccess(res.data));
        } catch ({message}) {
            yield put(getReportsFailure(message))
        }
    }
}


export function* workAddReport(action) {
    const idToken = getIdToken();
    try {
        yield call(apiCall, "/reports", "post", {...action.payload.data}, idToken);
        yield put(getCategoryReports({categoryId: ""}))
        yield put(setErrorAdding(""))
        action.payload.close()
        toast.success("Report added successfully");
    } catch ({message}) {
        yield put(getReportsFailure(message));
    } finally {
        yield put(addReportSuccess())
    }
}

export function* workDeleteReport(action) {
    const idToken = getIdToken();
    try {
        yield call(apiCall, `/reports/${action.payload.id}`, "delete", null, idToken);
        yield put(getCategoryReports({categoryId: [action.payload.categoryId]}))
        toast.success("Report removed successfully");
    } catch ({message}) {
        toast.error(message);
    }
}

export function* workEditReport(action) {
    const idToken = getIdToken();
    const {data} = action.payload;
    delete data.id;
    try {
        yield call(apiCall, `/reports/${action.payload.id}`, "put", data, idToken);
        yield put(addReportSuccess())
        yield put(getCategoryReports({categoryId: ""}))
        yield put(setErrorAdding(""))
        action.payload.close()
        toast.success("Report edited successfully");
    } catch ({message}) {
        toast.error(message);
    }
}

export function* workSavePortalReport(action) {
    const idToken = getIdToken();
    try {
        yield call(apiCall, `/portal_user/${action.payload?.userId}`, "post", action.payload.data, idToken);
        toast("Reports are assigned successfully!");
    } catch ({message}) {
        yield put(getReportsFailure(message));
    }
}

function* workGetEmbedToken(action) {
    try {
        const idToken = getIdToken();
        if (idToken) {
            const response = yield call(apiCall, `auth/embed_token/${action.payload}`, "get", null, idToken);
            yield put(setEmbedToken(response.data));
        }
    } catch ({message}) {
        yield put(getReportsFailure(message));
    }
}


export function* reportSaga() {
    yield takeLatest(addReport().type, workAddReport);
    yield takeLatest(deleteReport().type, workDeleteReport);
    yield takeLatest(editReport().type, workEditReport);
    yield takeLatest(saveReport().type, workSavePortalReport);
    yield takeLatest(getEmbedToken().type, workGetEmbedToken);
    yield takeLatest(getCategoryReports().type, workGetCategoryReports);
}

