import React, {useEffect, useMemo} from 'react';
import {
    Button,
    getKeyValue,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    Pagination,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow
} from "@nextui-org/react";
import {useDispatch, useSelector} from "react-redux";
import {getRole} from "../../redux/reducers/GroupReducer";

function ModalAssignRoles({
                              id,
                              onClose,
                              isOpen,
                              getAssignedRoles,
                              setAssignedRoles,
                              assignRolesToItem,
                              setSelectedItem,
                              selectedItem
                          }) {
    const dispatch = useDispatch()
    const [page, setPage] = React.useState(1);
    const rowsPerPage = 6; // Change to 10 as per your requirement
    const {roles, itemRoles, isLoading} = useSelector(state => state.roleReducer)
    const {isLoading: isAssignLoading} = useSelector(state => state.portalReducer)
    const {isAssigned} = useSelector(state => state.styleReducer)

    useEffect(() => {
        if (id) {
            dispatch(getRole())
            dispatch(getAssignedRoles({id: id}))
        }
    },[isAssigned])

    const paginatedRoles = useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage;
        return roles?.slice(startIndex, startIndex + rowsPerPage);
    }, [roles, page]);
    const pages = useMemo(() => {
        return roles?.length ? Math.ceil(roles.length / rowsPerPage) : 0;
    }, [roles?.length, rowsPerPage]);
    const loadingState = isLoading ? "loading" : "idle";

    function setSelected(e) {
        dispatch(setAssignedRoles(e))
    }

    function assignRoles() {
        dispatch(assignRolesToItem({
            id: id,
            roles: itemRoles === "all" ? roles.map(item => item.group_id) : Array.from(itemRoles)
        }))
    }


    return (
        <Modal placement={"center"} isOpen={isOpen} onClose={onClose}>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <p className={"text-800"}> Assign Roles to {selectedItem}</p>
                        </ModalHeader>
                        <ModalBody>
                            <Table
                                aria-label="Example table with client async pagination"
                                selectionMode="multiple"
                                selectedKeys={itemRoles}
                                onSelectionChange={setSelected}
                                bottomContent={
                                    pages > 0 ? (
                                        <div className="flex w-full justify-center">
                                            <Pagination
                                                isCompact
                                                showControls
                                                showShadow
                                                color="primary"
                                                page={page}
                                                total={pages}
                                                onChange={(page) => setPage(page)}
                                            />
                                        </div>
                                    ) : null
                                }
                            >
                                <TableHeader>
                                    <TableColumn key="title">Title</TableColumn>
                                    {/*<TableColumn key="description">Description</TableColumn>*/}
                                </TableHeader>
                                <TableBody
                                    items={paginatedRoles ?? []}
                                    loadingContent={<Spinner/>}
                                    loadingState={loadingState}
                                >
                                    {(item) => (
                                        <TableRow key={item?.group_id}>
                                            {(columnKey) => {
                                                return <TableCell>{getKeyValue(item, columnKey)}</TableCell>;
                                            }}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>

                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light"
                                    onPress={() => {
                                        onClose();
                                        dispatch(setSelectedItem(""))
                                    }
                                    }>
                                Cancel
                            </Button>
                            <Button onClick={assignRoles} color="primary" variant="light">
                                Assign
                                {isAssignLoading && <Spinner size={"sm"} color={"white"}/>}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

export default ModalAssignRoles;