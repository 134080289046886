import {createSlice} from "@reduxjs/toolkit"

const slice = createSlice({
    name: "roles",
    initialState: {
        roles: [],
        isLoading: false,
        isUpdateLoading: false,
        isSavedLoading: false,
        error: null,
        selectedGroupUser: "",
        groupInfoForUpdate: {},
        selectedRole: "",
        userRoles: [],
        categoryId: "",
        itemRoles: []
    },
    reducers: {
        setSelectedCategoryId(state, action) {
            state.categoryId = action.payload
        },
        getRole(state) {
            state.isLoading = true;
            state.error = null;
        },
        getRoleSuccess(state, action) {
            state.roles = action.payload
            state.isLoading = false
        },
        getRoleFailure(state, action) {
            state.error = action.payload
        },
        addGroup(state, action) {
            state.isSavedLoading = true
            action.payload.close()
        },
        changeSelectedGroup(state, action) {
            state.selectedGroup = action.payload
        },
        clearSelectedGroup(state) {
            state.selectedGroup = ""
        },
        assignReportToGroup: (state) => {
            state.isUpdateLoading = true;
        },
        getRoleReports(state, action) {
        },
        setGroupInfoForUpdate(state, action) {
            state.groupInfoForUpdate = action.payload
            state.isLoading = false
        },
        getRoleInfoForUpdate(state) {
            state.isUpdateLoading = true
        },
        getRoleInfoForUpdateSuccess(state) {
            state.isUpdateLoading = false
        },
        setSelectedRole(state, action) {
            state.selectedRole = action.payload
        },
        updateGroup(state, action) {
            state.isSavedLoading = true
        },
        groupSavedSuccessFully: (state) => {
            state.isSavedLoading = false
        },
        deleteRole(state, action) {
        },
        getUserRoles(state, action) {
        },
        setUserRoles(state, action) {
            state.itemRoles = action.payload
        }
    }
})


export const {
    getRole,
    getRoleFailure,
    getRoleSuccess,
    addGroup,
    assignReportToGroup,
    getRoleReports,
    setGroupInfoForUpdate,
    getRoleInfoForUpdate,
    setSelectedRole,
    updateGroup,
    deleteRole,
    getRoleInfoForUpdateSuccess,
    groupSavedSuccessFully,
    getUserRoles,
    setUserRoles,
    setSelectedCategoryId
} = slice.actions

export default slice.reducer

