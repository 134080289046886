import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    isActive: false,
    isAssigned: false

};

const slice = createSlice({
    name: "style",
    initialState,
    reducers: {
        toggle: (state) => {
            state.isActive = !state.isActive
        },
        setIsAssigned: (state) => {
            state.isAssigned = !state.isAssigned
        }
    }
});

export const {toggle, setIsAssigned} = slice.actions;
export default slice.reducer;
