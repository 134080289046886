import axios from "axios";
import {store} from "../store";
import {setIsExpired} from "../reducers/AuthReducer";


export function apiCall(url, method = "get", data, idToken) {
    return axios({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        url,
        method,
        data,
        headers: {
            "Authorization": idToken
        }
    }).catch(err=>{
        store.dispatch(setIsExpired(true))
    })
}