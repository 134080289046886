import React from 'react';
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";

function SkeletonLoading({count}) {
    return (
        <SkeletonTheme baseColor="#4797CE" highlightColor="#8DC4EB">
            <Skeleton height={"50px"} className={"mt-2"} count={count}/>
        </SkeletonTheme>
    );
}

export default SkeletonLoading;