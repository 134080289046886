import {combineReducers, configureStore} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import ReportReducer from "./reducers/ReportReducer";
import UserReducer from "./reducers/UserReducer";
import PortalReducer from "./reducers/PortalReducer";
import AuthReducer from "./reducers/AuthReducer";
import RoleReducer from "./reducers/GroupReducer";
import CategoryReducer from "./reducers/CategoryReducer";
import StyleReducer from "./reducers/StyleReducer";
import DomainReducer from "./reducers/DomainReducer";

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
    reportReducer: ReportReducer,
    userReducer: UserReducer,
    portalReducer: PortalReducer,
    authReducer: AuthReducer,
    roleReducer: RoleReducer,
    categoryReducer: CategoryReducer,
    domainReducer: DomainReducer,
    styleReducer: StyleReducer
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
        return getDefaultMiddleware({serializableCheck: false}).concat(sagaMiddleware);
    }
});

sagaMiddleware.run(rootSaga);

