import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import React from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {Accordion, AccordionItem, Avatar} from "@nextui-org/react";

function AuthenticatedContent() {
    const navigate = useNavigate()
    return (
        <>
            <Header />
            <div className="flex">
                <Sidebar navigate={navigate}/>
                <Outlet/>
            </div>
        </>
    );
}

export default AuthenticatedContent


