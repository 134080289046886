import React, {useEffect, useMemo, useState} from "react";
import {
    Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger,
    getKeyValue,
    Pagination,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableColumn,
    TableHeader,
    TableRow, useDisclosure, User
} from "@nextui-org/react";
import {BsThreeDotsVertical} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import ModalConfirmDelete from "../../modal/ModalConfirmDelete";
import {deleteRole, setSelectedRole} from "../../../redux/reducers/GroupReducer";
import ModalGroupSave from "../../modal/ModalGroupSave";
import ModalAssignReport from "../../modal/ModalAssignReport";
import {setSelectedReports} from "../../../redux/reducers/ReportReducer";
import PaginationUniv from "../../uiComponents/Pagination";
import {AiOutlineEdit} from "react-icons/ai";
import {MdOutlineDeleteOutline} from "react-icons/md";
import {setIsAssigned} from "../../../redux/reducers/StyleReducer";

function RoleList({roles, isLoading}) {

    const [page, setPage] = React.useState(1);
    const rowsPerPage = 9; // Change to 10 as per your requirement
    const dispatch = useDispatch()
    const {onOpen: onOpenConfirm, onClose: onCloseConfirm, isOpen: isOpenConfirm} = useDisclosure()
    const {onOpen, onClose, isOpen, onOpenChange} = useDisclosure()
    const {isOpen: isOpenReport, onClose: onCloseReport, onOpen: onOpenReport} = useDisclosure()
    const {selectedRole} = useSelector(state => state.roleReducer)

    useEffect(() => {
        if (!isOpenReport) {
            dispatch(setSelectedReports([]))
            dispatch(setSelectedRole(""))
        }
    }, [isOpenReport])

    const paginatedRoles = useMemo(() => {
        const startIndex = (page - 1) * rowsPerPage;
        return roles?.slice(startIndex, startIndex + rowsPerPage);
    }, [roles, page]);

    const pages = useMemo(() => {
        return roles?.length ? Math.ceil(roles.length / rowsPerPage) : 0;
    }, [roles?.length, rowsPerPage]);

    function handleDelete(item) {
        onOpenConfirm()
        dispatch(setSelectedRole(item))
    }

    const loadingState = isLoading ? "loading" : "idle";

    function handleEdit(item) {
        onOpen()
        dispatch(setSelectedRole(item))
    }

    function handleAssign(item) {
        onOpenReport()
        dispatch(setIsAssigned())
        dispatch(setSelectedRole(item))
    }

    return (
        <div>
            <Button onClick={() => {
                onOpen()
                dispatch(setSelectedRole({title: ""}))
            }}
                    className={"mb-5 float-right bg-secondColor text-white font-bold"}
                    radius={"sm"}>
                Add Role
            </Button>
            <Table
                aria-label="Example table with client async pagination"
                bottomContent={
                    pages > 0 ? (
                        <div className="flex w-full justify-center">
                            <PaginationUniv setPage={setPage} page={page} pages={pages} />
                        </div>
                    ) : null
                }
            >
                <TableHeader>
                    <TableColumn key="title">Title</TableColumn>
                    <TableColumn key="actions">Actions</TableColumn>
                </TableHeader>
                <TableBody
                    items={paginatedRoles ?? []}
                    loadingContent={<Spinner/>}
                    loadingState={loadingState}
                >
                    {(item) => (
                        <TableRow key={item?.group_id}>
                            {(columnKey) => {
                                if (columnKey !== "actions") {
                                    return (
                                        <TableCell>{getKeyValue(item, columnKey)}</TableCell>
                                    );
                                } else {
                                    return <TableCell>
                                        <div className="relative">
                                            <Dropdown className="bg-background border-1 border-default-200">
                                                <DropdownTrigger>
                                                    <Button isIconOnly radius="full" variant="light">
                                                        <BsThreeDotsVertical size={"24px"}/>
                                                    </Button>
                                                </DropdownTrigger>
                                                <DropdownMenu aria-label={"menu"}>
                                                    <DropdownItem onClick={() => handleAssign(item)}>
                                                        Assign Report
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => handleEdit(item)}>
                                                        <div className={'flex   gap-x-2  items-center justify-start'}>
                                                            <AiOutlineEdit/>
                                                            Edit
                                                        </div>
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => handleDelete(item)}>
                                                        <div className={'flex gap-x-2 items-center justify-start'}>
                                                            <MdOutlineDeleteOutline/>
                                                            Delete
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </TableCell>
                                }
                            }}
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <ModalConfirmDelete
                deleteItem={deleteRole}
                name={"Role"}
                itemTitle={selectedRole.title}
                id={selectedRole.group_id}
                isOpen={isOpenConfirm} onClose={onCloseConfirm}
            />
            <ModalGroupSave
                isOpen={isOpen}
                onClose={onClose}
                onOpenChange={onOpenChange}
            />

            <ModalAssignReport
                onClose={onCloseReport}
                isOpen={isOpenReport}
                role={selectedRole}
            />
        </div>
    );
}

export default RoleList;
