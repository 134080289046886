import {createSlice} from "@reduxjs/toolkit"

const slice = createSlice({
    name: "domain",
    initialState: {
        name: "",
        domains: [],
        isLoading: false,
        isSavedLoading: false,
        selectedDomain: "",
        domainRoles: [],
        isAssignRole: false
    },
    reducers: {
        getDomain(state) {
            state.isLoading = true;
            state.error = null;
        },
        setDomainRoles(state, {payload}) {
            state.domainRoles = payload
        },
        getDomainRoles(state) {

        },
        assignRolesToDomain(state) {
            state.isSavedLoading = true
        },
        getDomainSuccess(state, action) {
            state.domains = action.payload
            state.isLoading = false
        },
        getDomainFailure(state, action) {
            state.error = action.payload
        },
        addDomain(state, action) {
            state.isSavedLoading = true
        },
        setSelectedDomain: (state, action) => {
            state.selectedDomain = action.payload
        },
        setIsSavedLoading: (state, action) => {
            state.isSavedLoading = action.payload
        },
        deleteDomain: () => {
        },
        editDomain: (state) => {
            state.isSavedLoading = true
        },
        setName: (state, action) => {
            state.name = action.payload
        },
        setIsAssignRole:(state, action)=>{
            state.isAssignRole = action.payload;
        }
    }
})


export const {
    getDomain,
    getDomainSuccess,
    getDomainFailure,
    addDomain,
    setSelectedDomain,
    deleteDomain,
    setIsSavedLoading,
    editDomain,
    setName,
    assignRolesToDomain,
    getDomainRoles,
    setDomainRoles,
    setIsAssignRole
} = slice.actions

export default slice.reducer

