import React from 'react';
import {useAuth0} from "@auth0/auth0-react";
import AuthenticatedContent from "../../components/authenticatedContent/AuthenticatedContent";
import UnauthenticatedContent from "../../components/unAuthenticatedContent/UnauthenticatedContent";

function Home() {
    const {isAuthenticated, loginWithRedirect} = useAuth0();

    return (
        <div>
            {
                isAuthenticated ?
                    <AuthenticatedContent/>
                    :
                    <UnauthenticatedContent onLogin={loginWithRedirect}/>
            }

        </div>
    );
}


export default Home;
