import React, {useEffect} from 'react';
import CategoryList from "../../../components/admin/category/CategoryList";
import {useDispatch, useSelector} from "react-redux";
import {getCategory} from "../../../redux/reducers/CategoryReducer";


function Category() {

    const {categories, isLoading} = useSelector(state => state.categoryReducer)
    const {idToken} = useSelector(state => state.authReducer)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCategory())
    }, [idToken])


    return (
        <div>
            <CategoryList categories={categories} isLoading={isLoading}/>
        </div>
    );
}

export default Category;

