import {call, put, takeLatest} from "redux-saga/effects";
import {
    addGroup,
    assignReportToGroup,
    deleteRole,
    getRole,
    getRoleFailure,
    getRoleInfoForUpdate,
    getRoleInfoForUpdateSuccess,
    getRoleReports,
    getRoleSuccess,
    getUserRoles,
    groupSavedSuccessFully,
    setGroupInfoForUpdate,
    setSelectedRole,
    setUserRoles,
    updateGroup
} from "../../reducers/GroupReducer";
import {apiCall} from "../../apiCall/apiCall";
import {store} from "../../store";
import {toast} from "react-toastify";
import {getReportsFailure, setSelectedReports} from "../../reducers/ReportReducer";
import {getDomainRoles, setDomainRoles} from "../../reducers/DomainReducer";

const getIdToken = () => store.getState().authReducer.idToken;

export function* workGetRoles() {
    if (getIdToken()) {
        try {
            const response = yield call(apiCall, `/groups`, "get", null, getIdToken());
            yield put(getRoleSuccess(response.data));
        } catch ({message}) {
            yield put(getRoleFailure(message));
        }
    }
}

export function* workGetGroupAssignedReports(action) {
    try {
        const response = yield call(apiCall, `/groups/${action.payload}`, "get", null, getIdToken());
        yield put(setSelectedReports(response.data))
    } catch ({message}) {
        yield put(getReportsFailure(message));
    }
}

export function* workAddGroup(action) {
    try {
        yield call(apiCall, `/groups`, "post", action.payload.data, getIdToken());
        yield put(groupSavedSuccessFully());
        yield call(workGetRoles);
        yield put(setSelectedRole(""));
        toast.success("User Role added successfully");
    } catch ({message}) {
        yield put(getRoleFailure(message));
    }
}

export function* workAssignReportsToGroup(action) {
    try {
        yield call(apiCall, `/groups/assign_reports/${action.payload.groupId}`, "put", action.payload.data, getIdToken());
        yield put(getRoleInfoForUpdateSuccess())
        toast.success("Reports are assigned to the User Role successfully");
    } catch ({message}) {
        yield put(getRoleFailure(message));
    }
}

export function* workGetGroupInfoForUpdate(action) {
    try {
        const response = yield call(apiCall, `/groups/${action.payload}/users`, "get", null, getIdToken());
        yield put(getRoleInfoForUpdateSuccess())
        yield put(setGroupInfoForUpdate(response.data));
    } catch ({message}) {
        yield put(getRoleFailure(message));
    }
}

export function* workUpdateGroup(action) {
    try {
        yield call(apiCall, `/groups/${action.payload.groupId}`, "put", action.payload.data, getIdToken());
        yield put(groupSavedSuccessFully());
        yield call(workGetRoles);
        toast.success("User Role is updated successfully !")
        action.payload.close();
    } catch ({message}) {
        yield put(getRoleFailure(message));
    }
}

export function* workDeleteRole(action) {
    try {
        const response = yield call(apiCall, `/groups/${action.payload.id}`, "delete", null, getIdToken());
        yield call(workGetRoles);
        action.payload.close(response);
        toast.success("User Role is deleted successfully !");
    } catch ({message}) {
        yield put(getRoleFailure(message));
    }
}

export function* workGetUserRoles(action) {
    try {
        const response = yield call(apiCall, `/groups/user_roles/${action.payload.id.email}`, "get", null, getIdToken());
        yield put(setUserRoles(response.data))
    } catch ({message}) {
        yield put(getRoleFailure(message));
    }
}

export function* workGetDomainRoles(action) {
    try {
        const response = yield call(apiCall, `/domain/${action.payload.id}/assigned_roles`, "get", null, getIdToken());
        yield put(setUserRoles(response.data))
    } catch ({message}) {
        yield put(getRoleFailure(message));
    }
}


export function* groupSaga() {
    yield takeLatest(getRole().type, workGetRoles);
    yield takeLatest(addGroup().type, workAddGroup);
    yield takeLatest(assignReportToGroup().type, workAssignReportsToGroup);
    yield takeLatest(getRoleReports().type, workGetGroupAssignedReports);
    yield takeLatest(getRoleInfoForUpdate().type, workGetGroupInfoForUpdate);
    yield takeLatest(updateGroup().type, workUpdateGroup);
    yield takeLatest(deleteRole().type, workDeleteRole);
    yield takeLatest(getUserRoles().type, workGetUserRoles)
    yield takeLatest(getDomainRoles().type, workGetDomainRoles)
}
