import {call, put, takeLatest} from "redux-saga/effects";
import {
    addCategory,
    deleteCategory,
    getCategory,
    getCategoryFailure,
    getCategorySuccess,
    savedCategorySuccessfully,
    updateCategory
} from "../../reducers/CategoryReducer";
import {apiCall} from "../../apiCall/apiCall";
import {store} from "../../store";
import {toast} from "react-toastify";

const getIdToken = () => store.getState().authReducer.idToken;

export function* workGetCategory() {
    try {
        const idToken = getIdToken();
        if (idToken) {
            const response = yield call(apiCall, `/category/all`, "get", null, idToken);
            yield put(getCategorySuccess(response.data));
        }
    } catch ({message}) {
        yield put(getCategoryFailure(message));
    }
}

export function* workAddCategory(action) {
    const idToken = getIdToken();
    try {
        yield call(apiCall, `/category`, "post", action.payload.data, idToken);
        yield put(getCategory())
        yield put(savedCategorySuccessfully())
        action.payload.onClose()
        toast.success("category is added !")
    } catch ({message}) {
        yield put(getCategoryFailure(message));
    }
}

export function* workDeleteCategory(action) {
    const idToken = getIdToken();
    try {
        yield call(apiCall, `/category/${action.payload.id}`, "delete", null, idToken);
        yield call(workGetCategory)
        toast.success("category is deleted !")
    } catch ({message}) {
        yield put(getCategoryFailure(message));
    }
}

export function* workUpdateCategory(action) {
    const idToken = getIdToken();
    try {
        yield call(apiCall, `/category/${action.payload.categoryId}`, "put", action.payload.data, idToken);
        yield call(workGetCategory)
        action.payload.onClose()
        yield put(savedCategorySuccessfully())
        toast.success("category is updated !")
    } catch ({message}) {
        yield put(getCategoryFailure(message));
    }
}

export function* categorySaga() {
    yield takeLatest(getCategory().type, workGetCategory);
    yield takeLatest(addCategory().type, workAddCategory);
    yield takeLatest(deleteCategory().type, workDeleteCategory);
    yield takeLatest(updateCategory().type, workUpdateCategory);
}