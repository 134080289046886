import React, {useEffect, useState} from 'react';
import {Button, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spinner,} from '@nextui-org/react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {addGroup, updateGroup} from '../../redux/reducers/GroupReducer';

function ModalGroupSave({isOpen, onClose, onOpenChange}) {
    const [showTitleError, setShowTitleError] = useState("")
    const dispatch = useDispatch();
    const {
        register, reset, handleSubmit, formState: {errors},
    } = useForm();

    const {
        selectedRole,
        isSavedLoading
    } = useSelector((state) => state.roleReducer);

    useEffect(() => {
        reset({title: selectedRole.title})
    }, [selectedRole])

    function submit(data) {
        if (data.title.includes("|")) {
            return alert("Please don't enter |")
        }
        if (selectedRole.title !== "") {
            dispatch(updateGroup({
                data: data,
                groupId: selectedRole.group_id,
                close: onClose,
            }));
        } else {
            dispatch(addGroup({data, close: onClose}));
        }
    }


    return (
        <Modal placement="center" isOpen={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            {selectedRole?.title ? <h3>Update Role</h3> : <h3>Add Role</h3>}
                        </ModalHeader>
                        <ModalBody>
                            {
                                <form id="form" onSubmit={handleSubmit(submit)}>
                                    <Input
                                        {...register('title', {required: true})}
                                        className={"mt-2"}
                                        radius={"none"}
                                        type="text"
                                        placeholder={"Role title"}
                                    />
                                    {errors.title && <p className="text-red-500">Title is required.</p>}
                                    {showTitleError && <p className="text-red-500">Please don't use | </p>}
                                </form>
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button color="danger" variant="light" onPress={onClose}>
                                Close
                            </Button>
                            <Button type="submit" form="form" color="primary">
                                Save {isSavedLoading && <Spinner color={"white"} size={"sm"}/>}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
}

export default ModalGroupSave;