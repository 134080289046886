import React, {useEffect} from 'react';
import {PowerBIEmbed} from 'powerbi-client-react';
import {models} from 'powerbi-client';
import {useDispatch, useSelector} from 'react-redux';
import {useAuth0} from "@auth0/auth0-react";
import {getEmbedToken, setEmbedKey} from "../../redux/reducers/PortalReducer";
import Loading from "../loading/Loading";
import {useParams} from "react-router-dom";

function Content() {

    const {reportId, embedKey, embedTokenObj, isLoading, embedReportId} = useSelector(state => state.portalReducer);
    const {idToken} = useSelector(state => state.authReducer)
    const dispatch = useDispatch()
    const {user} = useAuth0();
    const {id} = useParams();
    const {link} = useParams();

    useEffect(() => {
        validateEmbedToken()
    }, [reportId, idToken])



    const validateEmbedToken = () => {
        console.log(reportId)
        if (reportId) {
            dispatch(getEmbedToken(reportId))
        }
    }
    useEffect(() => {
        dispatch(setEmbedKey())
    }, [embedTokenObj, idToken]);

    const isTokenExpired = (expDate) => {
        let expiration = new Date(expDate)
        let currentTime = new Date()
        if (isNaN(expiration)) {
            return true
        }
        return expiration - 5 * 60000 < new Date(currentTime.getTime());
    }

    return (
        isLoading ?
            <Loading type={"cylon"} color={"black"}/> :
            <div className="w-full h-full">
                {user && reportId && <section id="bi-report">
                    <PowerBIEmbed
                        key={embedKey}
                        embedConfig={{
                            type: 'report',
                            id: embedReportId,
                            accessToken: embedTokenObj?.token,
                            tokenType: models.TokenType.Embed,
                            settings: {
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: false
                                    }
                                },
                            }
                        }}
                        eventHandlers={new Map([
                            ['loaded', function () {
                                console.log('Report loaded');
                            }],
                            ['rendered', function () {
                                console.log('Report rendered');
                            }],
                            ['error', function (event) {
                                console.log(event.detail);
                            }],
                            ['visualClicked', () => console.log('visual clicked')],
                            ['pageChanged', (event) => console.log(event)]
                        ])}
                        cssClassName="bi-embedded"
                        getEmbeddedComponent={embeddedReport => {
                            window.report = embeddedReport;
                        }}
                    />
                </section>}
            </div>
    );


}


export default Content;
