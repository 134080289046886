import axios from "axios";

export function userApiCall(url, method = "get", data) {
    let token = localStorage.getItem("api_m_token")
    console.log(token)
    return axios({
        url,
        method,
        data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}