import React, {useRef} from 'react';
import {Link, NavLink} from "react-router-dom";
import logoImg from "../../../assets/ticketboat.png"
import  {HiOutlineUserGroup} from 'react-icons/hi'
import  {TbReportAnalytics} from 'react-icons/tb'
import  {BiUser,BiCategory} from 'react-icons/bi';
import {MdDomainAdd} from "react-icons/md";
function Sidebar() {

    const sidebarRef = useRef(null);


    return (
        <div>
            <div
                ref={sidebarRef}
                className={`bg-mainColor sticky top-0 min-h-[100vh] text-white w-64 space-y-6 py-7 px-2 left-0 transform transition-transform duration-300`}
            >
                <div className="flex justify-center mb-6">
                    <Link to={"/"} className="w-[160px] font-semibold">
                        <img src={logoImg} alt="#"/>
                    </Link>
                </div>

                <nav>
                    <NavLink to="/admin/user"
                             className="flex items-center space-x-2 px-4 py-2.5 rounded transition duration-200 hover:bg-secondColor mt-1">
                        <BiUser/>
                        <span>Users</span>
                    </NavLink>
                     <NavLink to="/admin/domain"

                             className="flex items-center space-x-2 px-4 py-2.5 rounded transition duration-200 hover:bg-secondColor mt-1">
                        <MdDomainAdd/>
                        <span>Domain</span>
                    </NavLink>
                    <NavLink to="/admin/role"
                             className="flex items-center space-x-2 px-4 py-2.5 rounded transition duration-200 hover:bg-secondColor mt-1">
                        <HiOutlineUserGroup/>
                        <span>User Roles</span>
                    </NavLink>
                    <NavLink to="/admin/report"
                             className="flex items-center space-x-2 px-4 py-2.5 rounded transition duration-200 hover:bg-secondColor mt-1">
                        <TbReportAnalytics/>
                        <span>Reports</span>
                    </NavLink>
                    <NavLink to="/admin/category"

                             className="flex items-center space-x-2 px-4 py-2.5 rounded transition duration-200 hover:bg-secondColor mt-1">
                        <BiCategory/>
                        <span>Report Categories</span>
                    </NavLink>

                </nav>

            </div>
        </div>
    );
}

export default Sidebar;
