import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    userRole: "",
    idToken: "",
    isExpired: false
};

const slice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setIsExpired: (state, {payload}) => {
            state.isExpired = payload
        },
        setUserRole: (state, {payload}) => {
            state.userRole = payload;
        },
        setIdTokenToLocalStorage: (state, {payload}) => {
            localStorage.setItem("idToken", payload)
            state.idToken = payload;
        },
        getIdTokenFromLocalStorage: (state) => {
            let idToken = localStorage.getItem("idToken")
            if (idToken) {
                state.idToken = idToken
            }
        }
    }
});

export const {setUserRole,setIsExpired, setIdTokenToLocalStorage,getIdTokenFromLocalStorage} = slice.actions;
export default slice.reducer;
